import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Drawer, Grid } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AutocompleteCustom from 'src/shared-components/AutocompleteCustom';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';

import { checkStatusShipping } from 'src/functions/order-statuts';
import { IOrderDetail } from 'src/model/order.modal';
import { getCity, getDelivery, getDistricts } from 'src/services/app.services';
import { createOrderCustomer, createOrderTracking, getTracking } from 'src/services/order.servies';
import { getProduct } from 'src/services/stock.services';
import useDebounce from 'src/shared-components/debounce';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useToast } from 'src/shared-components/Toast';
import { columnProductTracking } from 'src/utils/app-data';
import { currencyMark, numberFormat } from 'src/utils/utils';
import '../../../layout/defaultLayout.scss';
import '../order.scss';
import ProductTable from './productTable';

interface ICreateProps {
  setShowDrawer: (data: any | undefined) => void;
  showDrawer: boolean | undefined;
  productList: any;
  data: any;
  address?: any;
  refetch: any;
  refetchProd?: any;
}
const phoneRegExp = /(84|0[3|5|7|8|9|00])+([0-9]{8,12})\b/g;

const AddTracking = (props: ICreateProps) => {
  const { setShowDrawer, showDrawer, data, productList, refetch, refetchProd } = props;
  const toast = useToast();

  const dataOrder: IOrderDetail = data;

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [filterParams, setFilterParams] = useState<any>({ status: 'IN_PROCESS' });
  const [productTracking, setProductTracking] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<any>();
  const [trackingList, setTrackingList] = useState<any>([]);
  const [isBranchSame, setIsBranchSame] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchValue, 500);

  const ref = useRef<NodeJS.Timeout | undefined>(undefined);

  const schema = yup.object().shape({
    name: yup
      .string()
      .typeError('Tên khách hàng không được phép bỏ trống')
      .required('Tên khách hàng không được phép bỏ trống'),
    address: yup.string().typeError('Địa chỉ không được phép bỏ trống').required('Địa chỉ không được phép bỏ trống'),
    phone: yup
      .string()
      .required('Nhập số điện thoại')
      .matches(phoneRegExp, 'Số điện thoại không hợp lệ')
      .min(10, 'Số điện thoại không hợp lệ')
      .max(12, 'Số điện thoại không hợp lệ'),
    weight: yup.string().required('Vui lòng nhập khối lượng'),
    deliveryCOD: yup.string().required('Vui lòng nhập cod'),
    deliveryFreeCustomer: yup.string().required('Vui lòng nhập phí khách hàng trả'),
    deliveryFree: yup.string().required('Vui lòng nhập phí shipper'),
    deliveryTrucking: yup.lazy(value => {
      const delivery = methods.getValues()?.deliveryId?.code ?? '';
      if (delivery === '8') {
        return yup.string().nullable();
      }
      switch (typeof value) {
        case 'object':
          return yup.object().typeError('Vui lòng chọn mã vận đơn ').required('Vui lòng chọn mã vận đơn');
        case 'string':
          return yup.string().typeError('Vui lòng chọn mã vận đơn ').required('Vui lòng chọn mã vận đơn');
        default:
          return yup.mixed().typeError('Vui lòng chọn mã vận đơn ').required('Vui lòng chọn mã vận đơn');
      }
    }),
    deliveryId: yup.object().typeError('Vui lòng chọn đơn vị').required('Vui lòng chọn đơn vị'),
  });

  const defaultValues: any = useMemo(
    () => ({
      name: dataOrder?.customerAddress?.name || '',
      city: dataOrder?.customerAddress?.cities || '',
      address: dataOrder?.customerAddress?.address || '',
      district: dataOrder?.customerAddress?.district || '',
      email: dataOrder?.customerAddress?.email || '',
      phone: dataOrder?.customerAddress?.phone || '',
      note: '',
      deliveryTrucking: '',
      deliveryFree: '0',
      deliveryFreeCustomer: '0',
      deliveryCOD: (parseFloat(dataOrder?.total ?? 0) - parseFloat(dataOrder?.totalPaid ?? 0)).toString(),
      deliveryId: null,
      weight: '0',
      dimensionLong: 0,
      dimensionWide: 0,
      dimensionHigh: 0,
      dimension: '',
      shippingId: '',
      branch: '',
    }),
    [dataOrder],
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch } = methods;
  const { errors } = formState;
  const cityWatch = watch('city');
  const deliveryTruckingWatch = watch('deliveryTrucking');
  const deliveryFreeWatch = watch('deliveryFree');
  const deliveryFreeCustomerWatch = watch('deliveryFreeCustomer');
  const deliveryCODWatch = watch('deliveryCOD');
  const weightWatch = watch('weight');
  const dimensionWatch = watch('dimension');
  const debouncedDimension = useDebounce(dimensionWatch, 600);
  const watchBranch = watch('branch');

  const { data: product } = useQuery(['product'], (params: any) => getProduct({ page: 1, pageSize: 10 }));
  const { data: cityData } = useQuery(['city'], () => getCity({}));
  const [cityID, setCityId] = useState<any>(dataOrder?.customerAddress?.cities?.id);

  const { data: districtData } = useQuery(['district', cityID], () => getDistricts(cityID), { enabled: !!cityID });
  const { data: deliveryData } = useQuery(['delivery'], () => getDelivery({}));
  const { data: truckingData } = useQuery(['trucking', { ...filterParams }], () => getTracking({ ...filterParams }));
  const [remainingProducts, setRemainingProducts] = useState<
    { arr: any[]; obj: Record<string, any>; checked: number } | undefined
  >(undefined);

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const mutationStock = useMutation({
    mutationFn: createOrderCustomer,
    onSuccess: (data: any) => {
      toast('success', 'Thêm khách hàng thành công');
      refetch();
      refetchProd();
      goBack();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const mutationOrderTracking = useMutation({
    mutationFn: createOrderTracking,
    onSuccess: () => {
      toast('success', 'Thêm shipping thành công');
      refetch();
      goBack();
      setSelected([]);
      refetchProd();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message || 'Thất bại');
      }
    },
  });
  useEffect(() => {
    if (!isEmpty(deliveryTruckingWatch?.id)) {
      const deliveryId = deliveryData?.data?.find((item: any) => item.id === deliveryTruckingWatch?.deliveryId);
      setValue('name', deliveryTruckingWatch?.customerAddress?.name);
      setValue('city', deliveryTruckingWatch?.customerAddress?.cities);
      setValue('district', deliveryTruckingWatch?.customerAddress?.district);
      setValue('email', deliveryTruckingWatch?.customerAddress?.email || '');
      setValue('phone', deliveryTruckingWatch?.customerAddress?.phone);
      setValue('note', deliveryTruckingWatch?.customerAddress?.note);
      setValue('deliveryId', deliveryId);
      setValue('shippingId', deliveryTruckingWatch?.id);
      setDisabled(true);
    } else {
      if (!isEmpty(deliveryTruckingWatch)) {
        if (!isEmpty(deliveryTruckingWatch?.id)) {
          setDisabled(true);
        }
      } else {
        setDisabled(false);
      }
    }
  }, [deliveryTruckingWatch]);

  useEffect(() => {
    const dimension = debouncedDimension.split(',');
    setValue('deliveryFree', currencyMark(deliveryFreeWatch));
    setValue('deliveryFreeCustomer', currencyMark(deliveryFreeCustomerWatch));
    setValue('deliveryCOD', currencyMark(deliveryCODWatch));
    setValue('weight', currencyMark(weightWatch));
    setValue('dimensionHigh', dimension[2]);
    setValue('dimensionLong', dimension[0]);
    setValue('dimensionWide', dimension[1]);
  }, [deliveryFreeWatch, deliveryFreeCustomerWatch, deliveryCODWatch, weightWatch, debouncedDimension]);

  useEffect(() => {
    const city: any = cityWatch;
    if (city) {
      setCityId(city?.id);
    }
  }, [cityWatch]);

  useEffect(() => {
    let arr: any = [];
    if (selected.length > 1) {
      for (var i = 0; i < selected?.length - 1; i++) {
        var currentValue = watchBranch[i];
        var nextValue = watchBranch[i + 1];
        if (currentValue?.codeName === nextValue?.codeName) {
          setIsBranchSame(true);
        } else {
          setIsBranchSame(false);
        }
      }
    } else {
      setIsBranchSame(true);
    }
  }, [JSON.stringify(watchBranch), selected, dataOrder]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [data]);

  useEffect(() => {
    if (!isEmpty(truckingData?.data?.data)) {
      let tracking = [...truckingData?.data?.data];
      setTrackingList(tracking);
    } else {
      let tracking: any = [];
      setTrackingList(tracking);
    }
  }, [truckingData]);

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      deliveryTrucking: debouncedSearch?.data,
      status: 'IN_PROCESS',
    });
  }, [debouncedSearch]);

  const calRemainingProduct = () => {
    if (!data?.shippingOrder && !data?.orderProducts) return;
    const shippingOrder = (data.shippingOrder as any[]).reduce<Record<string, any>>((acum, so) => {
      let tmp = (so.shippingOrderDetail as any[]).reduce<Record<string, any>>(
        (_acum, sod) => ({ ..._acum, [sod.orderDetail.productId]: { ...sod.orderDetail, checked: false } }),
        {},
      );
      return {
        ...acum,
        ...tmp,
      };
    }, {});
    const tmp = (data.orderProducts as any[]).reduce<{ arr: any[]; obj: Record<string, any> }>(
      (acum, order) => {
        if (!shippingOrder?.[order.productId]) {
          let tmp = { ...order, checked: false };
          return {
            arr: [...acum.arr, tmp],
            obj: { ...acum.obj, [order.productId]: tmp },
          };
        }
        return acum;
      },
      { arr: [], obj: {} },
    );
    setRemainingProducts({ ...tmp, checked: 0 });
  };

  useEffect(() => {
    if (data?.shippingOrder && data?.orderProducts) {
      ref.current = setTimeout(calRemainingProduct, 0);
    }

    return () => clearTimeout(ref.current);
  }, [data, mutationOrderTracking.isSuccess]);

  const handleClick = (idx: number, checked: boolean) => {
    if (!remainingProducts) return;
    let arr = remainingProducts?.arr;
    arr[idx].checked = checked;
    let obj = remainingProducts.obj;
    obj[arr[idx].productId].checked = checked;
    setRemainingProducts(pre => ({
      arr,
      obj,
      checked: checked ? (pre?.checked || 0) + 1 : (pre?.checked as number) - 1,
    }));
  };

  const handleClickOrUnClickAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked;
    if (!remainingProducts) return;
    let arr: any[] = [];
    let obj = {};
    remainingProducts.arr.forEach(product => {
      arr.push({ ...product, checked });
      obj = {
        ...obj,
        [product.productId]: {
          ...product,
          checked,
        },
      };
    });
    setRemainingProducts({ arr, obj, checked: checked ? arr.length : 0 });
  };

  const handleSelectCity = (idx: number, value: any) => {
    setRemainingProducts(pre => {
      if (!pre) return undefined;
      let arr = pre.arr;
      let product = arr[idx];
      product.product.branch = value.codeName;
      let obj = pre.obj;
      obj[product.productId].product.branch = value.codeName;
      return {
        ...pre,
        arr,
        obj,
      };
    });
  };

  const goBack = () => {
    reset();
    setShowDrawer(false);
  };

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
  };

  const onChangeCustomer = (data: any) => {
    setSearchValue({
      data,
    });

    // clear Mã vận đơn
    setValue('deliveryTrucking', null);
  };

  const onSubmit = (values: any) => {
    if (remainingProducts?.checked) {
      let filtedProducts = remainingProducts.arr.filter(e => e.checked);
      let isSameBranch = true;
      if (remainingProducts.checked > 1) {
        isSameBranch = filtedProducts.every((e, idx) => {
          if (idx === filtedProducts.length - 1) {
            return true;
          }
          return e.product.branch === filtedProducts[idx + 1].product.branch;
        });
      }
      if (isSameBranch) {
        const params = {
          name: values.name,
          address: values.address,
          phone: values.phone,
          citiesId: values?.city?.id,
          districtId: values?.district?.id,
          deliveryTrucking: values?.deliveryTrucking?.deliveryTrucking || values?.deliveryTrucking,
          deliveryFree: numberFormat(values?.deliveryFree),
          deliveryFreeCustomer: numberFormat(values?.deliveryFreeCustomer),
          deliveryCOD: numberFormat(values.deliveryCOD),
          deliveryId: values?.deliveryId.id,
          weight: numberFormat(values?.weight),
          dimensionLong: Number(values?.dimensionLong) || undefined,
          dimensionWide: Number(values?.dimensionWide) || undefined,
          dimensionHigh: Number(values?.dimensionHigh) || undefined,
          items: filtedProducts.map(e => e.id),
          id: dataOrder?.id,
          note: values?.note || ' ',
          shippingId: values?.shippingId || undefined,
          branch: filtedProducts[0].product.branch,
          user: user?.userName,
        };
        mutationOrderTracking.mutate(params);
      } else {
        toast('warning', 'Vui lòng chọn đúng chi nhánh');
      }
    } else {
      toast('warning', 'Vui lòng chọn sản phẩm');
    }
  };

  const a = useCallback(
    (option: any, props: any, state: any) => {
      const checkBackground = () => {
        if (state?.index % 2 !== 0) {
          return { background: '#f5f7fa' };
        } else {
          return { background: 'white' };
        }
      };
      return (
        <>
          <div style={option.id === 0 ? {} : { backgroundColor: checkBackground().background }}>
            <div style={{ paddingBottom: '-2px', paddingTop: '-2px', display: 'flex' }} {...props}>
              <div style={{ width: '60%', justifyContent: 'center', display: 'flex' }}>
                <span> {option?.deliveryTrucking}</span>
              </div>
              <div style={{ width: '40%' }}>
                <span>{checkStatusShipping(option?.status).status}</span>
              </div>
            </div>
          </div>
        </>
      );
    },
    [undefined],
  );

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Thêm đơn vận chuyển</span>
            </div>
          </div>
          <div style={{ marginBottom: '15px', marginLeft: '20px', marginRight: '20px' }} className={'content'}>
            {remainingProducts && (
              <ProductTable
                rows={remainingProducts}
                columns={columnProductTracking}
                deleteProduct={() => {}}
                checkboxSelection={true}
                handleSelectAllClick={handleClickOrUnClickAll}
                handleClick={handleClick}
                handleSelectCity={handleSelectCity}
                {...props}
              />
            )}
          </div>

          <div className="content-drawer">
            <div className="title-group">
              <span>Thông tin vận chuyển</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Người nhận'} fullWidth>
                    <InputField
                      name={'name'}
                      placeholder={''}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message as string}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Địa chỉ'} fullWidth>
                    <InputField
                      name={'address'}
                      helperText={errors?.address?.message as string}
                      error={Boolean(errors.address)}
                      placeholder={''}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Điện thoại'} fullWidth>
                    <InputField
                      name={'phone'}
                      helperText={errors?.phone?.message as string}
                      error={Boolean(errors?.phone)}
                      placeholder={'0'}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Tỉnh/Thành'} fullWidth>
                    <SelectCustom
                      name={'city'}
                      placeholder={''}
                      error={Boolean(errors?.city)}
                      helperText={errors?.city?.message as string}
                      options={cityData?.data || []}
                      optionLabelKey={'name'}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Email'} fullWidth>
                    <InputField
                      name={'email'}
                      helperText={errors?.email?.message as string}
                      error={Boolean(errors.email)}
                      placeholder={''}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Quận/Huyện'} fullWidth>
                    <SelectCustom
                      name={'district'}
                      placeholder={''}
                      options={districtData?.data || []}
                      optionLabelKey={'name'}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>

                <Grid item xs={6}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField
                      name={'note'}
                      helperText={errors?.note?.message as string}
                      error={Boolean(errors.note)}
                      placeholder={''}
                      rows={3}
                      multiline={true}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Đơn vị vận chuyển</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Đơn vị'} fullWidth>
                    <SelectCustom
                      options={deliveryData?.data}
                      placeholder={''}
                      name={'deliveryId'}
                      error={Boolean(errors?.deliveryId)}
                      helperText={errors?.deliveryId?.message as string}
                      disabled={disabled}
                      className={disabled ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Mã vận đơn'} fullWidth>
                    <AutocompleteCustom
                      style={{ width: '100%' }}
                      name={'deliveryTrucking'}
                      placeholder={'Tìm mã vận đơn'}
                      error={Boolean(errors?.deliveryTrucking)}
                      helperText={errors?.deliveryTrucking?.message as string}
                      options={trackingList || []}
                      renderOption={a}
                      onChangeValue={onChangeCustomer}
                      optionLabelKey={'deliveryTrucking'}
                      freeSolo
                      isAutoSelected={true}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Phí ship khách hàng trả'} fullWidth>
                    <InputField
                      name={'deliveryFreeCustomer'}
                      helperText={errors?.deliveryFreeCustomer?.message as string}
                      error={Boolean(errors.deliveryFreeCustomer)}
                      placeholder={'0'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Tiền thu hộ COD'} fullWidth>
                    <InputField
                      name={'deliveryCOD'}
                      helperText={errors?.deliveryCOD?.message as string}
                      error={Boolean(errors.deliveryCOD)}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Thông tin gói hàng</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Khối lượng(kg)'} fullWidth>
                    <InputField
                      name={'weight'}
                      placeholder={'0'}
                      error={Boolean(errors.weight)}
                      helperText={errors?.weight?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Kích thước'} fullWidth>
                    <InputField
                      name={'dimension'}
                      helperText={errors?.address?.message as string}
                      error={Boolean(errors.address)}
                      placeholder={'dài, rộng, cao'}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button disabled={mutationOrderTracking.isLoading} className={'btn-tertiary-normal'} type="submit">
                  Lưu thông tin
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default AddTracking;
